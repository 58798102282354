<template>
  <h2>Map</h2>
  <p>
    Map представляет структуру данных, которая может хранить ключи со значениями. В JavaScript есть встроенный класс Map. Мы можем создать объект этого класса с помощью конструктора. Конструктор может либо ничего не принимать (тогда создаётся пустой Map), либо принимать любой итерируемый объект, элементами которого будут массивами с двумя значениями, первое из которых ключ, второе значение. В этом случае объект Map будет заполнен переданными данными. Например:
  </p>
  <Code
    lang="js"
    code="`
      const a = new Map()
      const b = new Map([[1, 'a'], [2, []], [[], 3], [{}, false]])
      console.log(a)
      console.log(b)
    `"
  />
  <p>Результат:</p>
  <CodeResult
    code="`
      Map(0) {}
      Map(4) { 1 => 'a', 2 => [], [] => 3, {} => false }
    `"
  />
  <p>В отличие от простых объектов, как мы видим, ключами Map могут быть любые объекты, а не только строки. Кроме того, у объектов Map можно узнать длину, используя свойство size.</p>
  <p>У класса Map есть следующие методы:</p>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Метод</th>
        <th scope="col">Описание</th>
        <th scope="col">Принимает</th>
        <th scope="col">Возвращает</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>set</td>
        <td>Добавляет пару ключ-значение в мап, если такого ключа там не было, либо изменяет значение, если такой ключ был</td>
        <td>1 - ключ, 2 - значение</td>
        <td>Сам объект Map</td>
      </tr>
      <tr>
        <td>get</td>
        <td>Возвращает значение по ключу. Если ключа не было, вернёт undefined</td>
        <td>1 - ключ</td>
        <td>Значение ключа или undefined</td>
      </tr>
      <tr>
        <td>clear</td>
        <td>Очищает мап (удаляет все элементы)</td>
        <td>ничего</td>
        <td>undefined</td>
      </tr>
      <tr>
        <td>delete</td>
        <td>Удаляет переданный ключ из множества</td>
        <td>1 - удаляемый ключ</td>
        <td>true, если ключ был удалён;<br>false, если ключа не было в мапе</td>
      </tr>
      <tr>
        <td>has</td>
        <td>Проверяет, есть ли ключ в мапе</td>
        <td>1 - проверяемый ключ</td>
        <td>true, если есть;<br>false, если нет</td>
      </tr>
      <tr>
        <td>forEach</td>
        <td>Аналогичен forEach у массивов, только передаваемая функция будет первым параметром принимать значение, вторым ключ, а третьим саму мапу</td>
        <td>1 - функция</td>
        <td>undefined</td>
      </tr>
      <tr>
        <td>[Symbol.iterator]</td>
        <td>Итератор. Благодаря ему можно использовать spread, for-of с мапой, и всё остальное, что использует итератор. Каждый элемент будет массивом из двух значений (первый элемент - ключ, второй - значение)</td>
        <td>ничего</td>
        <td>Объект с методом next</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Code from '@/components/ui/Code'
import CodeResult from '@/components/ui/CodeResult'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>

<style scoped>
.table {
  margin: 20px auto;
  border-collapse: collapse;
}
.table th, .table td {
  border: 1px solid black;
  padding: 5px;
}
</style>
