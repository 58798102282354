<template>
  <h2>Множество (Set)</h2>
  <p>
    Множества представляют структуру данных, которая может хранить только уникальные значения. В JavaScript есть встроенный класс Set. Мы можем создать объект этого класса с помощью конструктора. Конструктор может либо ничего не принимать (тогда создаётся пустое множество), либо принимать любой итерируемый объект (например, массив, строку, другое множество, любой объект с реализованным методом Symbol.iterator). В этом случае множество будет заполнено элементами из переданного объекта. Например:
  </p>
  <Code
    lang="js"
    code="`
      const a = new Set()
      const b = new Set([1, 2, 3, 3, 2, 1])
      const c = new Set('abracadabra')
      console.log(a)
      console.log(b)
      console.log(c)
    `"
  />
  <p>Результат:</p>
  <CodeResult
    code="`
      Set(0) {}
      Set(3) { 1, 2, 3 }
      Set(5) {'a', 'b', 'r', 'c', 'd'}
    `"
  />
  <p>Обратите внимание, что в b каждое число попало только один раз. Множество не добавляет объект, если он уже есть во множестве.</p>
  <p>У множества есть поле size, содержащее количество элементов во множестве</p>
  <p>У класса Set есть следующие методы:</p>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Метод</th>
        <th scope="col">Описание</th>
        <th scope="col">Принимает</th>
        <th scope="col">Возвращает</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>add</td>
        <td>Добавляет элемент во множество, если его там не было</td>
        <td>1 - добавляемое значение</td>
        <td>Само множество</td>
      </tr>
      <tr>
        <td>clear</td>
        <td>Очищает множество (удаляет все элементы)</td>
        <td>ничего</td>
        <td>undefined</td>
      </tr>
      <tr>
        <td>delete</td>
        <td>Удаляет переданное значение из множества</td>
        <td>1 - удаляемое значение</td>
        <td>true, если элемент был удалён;<br>false, если элемента не было во множестве</td>
      </tr>
      <tr>
        <td>has</td>
        <td>Проверяет, есть ли значение во множестве</td>
        <td>1 - проверяемое значение</td>
        <td>true, если есть;<br>false, если нет</td>
      </tr>
      <tr>
        <td>forEach</td>
        <td>Аналогичен forEach у массивов, только передаваемая функция будет и первым, и вторым параметром принимать значение из множества (у массивов вторым параметром передаётся индекс)</td>
        <td>1 - функция</td>
        <td>undefined</td>
      </tr>
      <tr>
        <td>[Symbol.iterator]</td>
        <td>Итератор. Благодаря ему можно использовать spread, for-of со множеством, и всё остальное, что использует итератор</td>
        <td>ничего</td>
        <td>Объект с методом next</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Code from '@/components/ui/Code'
import CodeResult from '@/components/ui/CodeResult'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>

<style scoped>
.table {
  margin: 20px auto;
  border-collapse: collapse;
}
.table th, .table td {
  border: 1px solid black;
  padding: 5px;
}
</style>
