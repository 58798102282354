import Simple from '@/components/task-templates/Simple'
import CodeTest from '@/components/task-templates/CodeTest'
import SetIntro from './SetIntro'
import MapIntro from './MapIntro'

export default [
  {
    component: SetIntro,
    label: '!'
  },
  {
    component: Simple,
    name: 'Уникальные символы массивом',
    text: 'Дана строка \\(s\\). Выведите количество уникальных символов этой строке, используя массив',
    note: null,
    input: '\\(s\\) - строка',
    output: 'Количество уникальных символов',
    examples: [
      {
        input: "'abcabcd'",
        output: '4'
      },
      {
        input: "'a b c'",
        output: '4'
      },
      {
        input: "'1122'",
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Уникальные символы объектом',
    text: 'Дана строка \\(s\\). Выведите количество уникальных символов этой строке, используя объект',
    note: null,
    input: '\\(s\\) - строка',
    output: 'Количество уникальных символов',
    examples: [
      {
        input: "'abcabcd'",
        output: '4'
      },
      {
        input: "'a b c'",
        output: '4'
      },
      {
        input: "'1122'",
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Уникальные символы множеством',
    text: 'Дана строка \\(s\\). Выведите количество уникальных символов этой строке, используя Set',
    note: null,
    input: '\\(s\\) - строка',
    output: 'Количество уникальных символов',
    examples: [
      {
        input: "'abcabcd'",
        output: '4'
      },
      {
        input: "'a b c'",
        output: '4'
      },
      {
        input: "'1122'",
        output: '2'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Уникальные объекты массивом',
    text: 'Дан массив объектов \\(a\\). Выведите количество уникальных объектов в этом массиве, используя массив',
    note: null,
    input: '\\(a\\) - массив объектов',
    output: 'Количество уникальных объектов',
    examples: [
      {
        input: `
          const x = {}
          const y = {}
          const z = {}
          task4([x, y, z, x, y, z])
          task4([x, x, x, x, x, x])
          task4([y, y, z, z, y, z])
        `,
        output: `
          3
          1
          2
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Уникальные объекты множеством',
    text: 'Дан массив объектов \\(a\\). Выведите количество уникальных объектов в этом массиве, используя Set',
    note: null,
    input: '\\(a\\) - массив объектов',
    output: 'Количество уникальных объектов',
    examples: [
      {
        input: `
          const x = {}
          const y = {}
          const z = {}
          task5([x, y, z, x, y, z])
          task5([x, x, x, x, x, x])
          task5([y, y, z, z, y, z])
        `,
        output: `
          3
          1
          2
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма множества',
    text: 'Дано множество из чисел \\(a\\). Выведите сумму чисел из множества',
    note: null,
    input: '\\(a\\) - множество из чисел',
    output: 'Сумма',
    examples: [
      {
        input: 'new Set([1, 1, 2, 2, 3, 3])',
        output: '6'
      },
      {
        input: 'new Set([2, 2, 2, 2, 2, 2])',
        output: '2'
      },
      {
        input: 'new Set([1, 2, 3, 4, 5, 6])',
        output: '21'
      }
    ]
  },
  {
    component: Simple,
    name: 'Проверка вхождения',
    text: 'Дано множество из чисел \\(a\\) и число \\(x\\). Выведите true, если \\(x\\) есть в множестве, и false если нет',
    note: null,
    input: '\\(a\\) - множество из чисел, \\(x\\) - число',
    output: 'true или false',
    examples: [
      {
        input: 'new Set([1, 1, 2, 2, 3, 3]), 2',
        output: 'true'
      },
      {
        input: 'new Set([2, 2, 2, 2, 2, 2]), 3',
        output: 'false'
      },
      {
        input: 'new Set([1, 2, 3, 4, 5, 6]), 0',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разность массивов',
    text: 'Даны массивы из чисел \\(a\\) и \\(b\\). Выведите только те элементы \\(a\\), которых нет в \\(b\\)',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из чисел',
    output: 'Числа в строку через пробел',
    examples: [
      {
        input: '[1, 1, 2, 2, 3, 3], [2, 2, 2]',
        output: '1 1 3 3'
      },
      {
        input: '[2, 3, 4, 2, 3, 4], [1, 3, 5]',
        output: '2 4 2 4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Множество из объединения',
    text: 'Даны массивы из чисел \\(a\\) и \\(b\\). Выведите только уникальные элементы из обоих массивов',
    note: null,
    input: '\\(a\\) и \\(b\\) - массивы из чисел',
    output: 'Числа в строку через пробел',
    examples: [
      {
        input: '[1, 1, 2], [2, 3, 3]',
        output: '1 2 3'
      },
      {
        input: '[1, 2, 3, 4], [4, 3, 2, 1]',
        output: '1 2 3 4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удаление из множества',
    text: 'Даны множество из чисел \\(a\\) и массив \\(b\\). Удалите из \\(a\\) все элементы \\(b\\). Выведите все элементы \\(a\\)',
    note: null,
    input: '\\(a\\) - множество, \\(b\\) - массив',
    output: 'Числа в строку через пробел',
    examples: [
      {
        input: 'new Set([1, 2, 3, 4]), [2, 3, 3]',
        output: '1 4'
      },
      {
        input: 'new Set([1, 2, 3, 4]), [1, 2]',
        output: '3 4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Отмеченные чекбоксы',
    text: 'Дан массив из чисел \\(a\\). Каждое число из массива - это идентификатор чекбокса, по которому кликнули. При первом нажатии на чекбокс он должен стать отмеченным, при втором неотмеченным, при третьем снова отмеченным и т. д.. В массиве дана последовательность кликов по чекбоксам. Выведите идентификаторы отмеченных чекбоксов после этих кликов',
    note: null,
    input: '\\(a\\) - массив из чисел',
    output: 'Числа в строку через пробел',
    examples: [
      {
        input: '[1, 2, 3, 1, 1, 2]',
        output: '1 3'
      },
      {
        input: '[4, 8, 10, 7, 10, 8, 9]',
        output: '4 7 9'
      },
      {
        input: '[1, 2, 3, 1, 2, 3, 1, 2, 3]',
        output: '1 2 3'
      }
    ]
  },
  {
    component: MapIntro,
    label: '!'
  },
  {
    component: Simple,
    name: 'Количество символов массивом',
    text: 'Дана строка \\(s\\). Выведите количество каждого символа в ней, используя массив (на каждой строке через пробел символ и его количество). Порядок символов не важен',
    note: null,
    input: '\\(s\\) - строка',
    output: 'Пары символ-количество',
    examples: [
      {
        input: "'abcabcd'",
        output: 'a 2\nb 2\nc 2\nd 1'
      },
      {
        input: "'a b c'",
        output: 'a 1\n  2\nb 1\nc 1'
      },
      {
        input: "'1122'",
        output: '1 2\n2 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество символов объектом',
    text: 'Дана строка \\(s\\). Выведите количество каждого символа в ней, используя объект (на каждой строке через пробел символ и его количество). Порядок символов не важен',
    note: null,
    input: '\\(s\\) - строка',
    output: 'Пары символ-количество',
    examples: [
      {
        input: "'abcabcd'",
        output: 'a 2\nb 2\nc 2\nd 1'
      },
      {
        input: "'a b c'",
        output: 'a 1\n  2\nb 1\nc 1'
      },
      {
        input: "'1122'",
        output: '1 2\n2 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество символов через Map',
    text: 'Дана строка \\(s\\). Выведите количество каждого символа в ней, используя Map (на каждой строке через пробел символ и его количество). Порядок символов не важен',
    note: null,
    input: '\\(s\\) - строка',
    output: 'Пары символ-количество',
    examples: [
      {
        input: "'abcabcd'",
        output: 'a 2\nb 2\nc 2\nd 1'
      },
      {
        input: "'a b c'",
        output: 'a 1\n  2\nb 1\nc 1'
      },
      {
        input: "'1122'",
        output: '1 2\n2 2'
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Количество объектов массивом',
    text: 'Дан массив объектов \\(a\\). Выведите количество каждого объекта в нём, используя массив (на каждой строке через пробел объект и его количество). Порядок объектов не важен',
    note: null,
    examples: [
      {
        input: `
          const x = { a: 1 }
          const y = { b: 2 }
          const z = { c: 3 }
          task15([x, y, z, x, y, z])
          console.log('---')
          task15([x, x, x, x, x, x])
          console.log('---')
          task15([y, y, z, z, y, z])
        `,
        output: `
          {a: 1} 2
          {b: 2} 2
          {c: 3} 2
          ---
          {a: 1} 6
          ---
          {b: 2} 3
          {c: 3} 3
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Количество объектов через Map',
    text: 'Дан массив объектов \\(a\\). Выведите количество каждого объекта в нём, используя Map (на каждой строке через пробел объект и его количество). Порядок объектов не важен',
    note: null,
    examples: [
      {
        input: `
          const x = { a: 1 }
          const y = { b: 2 }
          const z = { c: 3 }
          task16([x, y, z, x, y, z])
          console.log('---')
          task16([x, x, x, x, x, x])
          console.log('---')
          task16([y, y, z, z, y, z])
        `,
        output: `
          {a: 1} 2
          {b: 2} 2
          {c: 3} 2
          ---
          {a: 1} 6
          ---
          {b: 2} 3
          {c: 3} 3
        `
      }
    ]
  }
]
